import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { useIntl } from 'react-intl';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Row, Col } from 'emotion-flex';
import hexToRgba from 'hex-to-rgba';
import Container from './Container';
import Link from '../Link';
import presets from '../../styles/presets';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import footerLine from '../../assets/images/footer-line.svg';
import { ReactComponent as AbstractShape } from '../../assets/images/footer-abstract-shape.svg';
import { ContentfulFooterConnection } from '../../graphql-types';
import { MONO_SYSTEM_FONTS } from '../../styles/typography';
import { File } from '../../graphql-types';

const FooterHeading = styled('span')({
  marginBottom: '1.5rem',
  fontFamily: ['Source Code Pro'].concat(MONO_SYSTEM_FONTS).join(', '),
  fontWeight: 600,
  fontSize: '0.75rem',
  letterSpacing: 1.5,
  color: hexToRgba('#160B2C', 0.9),
});

const FooterLink = styled(Link)({
  fontFamily: ['Source Code Pro'].concat(MONO_SYSTEM_FONTS).join(', '),
  fontWeight: 500,
  fontSize: '0.75rem',
  letterSpacing: 1.5,
  color: 'inherit',
  textDecoration: 'none',
  marginBottom: '1rem',
  transition: 'opacity 0.15s cubic-bezier(0.4, 0.1, 1, 1)',

  '@media (hover: hover)': {
    '&:hover': {
      color: '#160B2C',
      textDecoration: 'none',
    },
  },
});

const FooterLegalLink = styled(Link)({
  display: 'block',
  padding: '0 1rem',
  fontWeight: 500,
  fontFamily: ['Source Code Pro'].concat(MONO_SYSTEM_FONTS).join(', '),
  fontSize: '0.75rem',
  color: hexToRgba('#160B2C', 0.6),
  letterSpacing: 0.5,
  transition: 'opacity 0.15s cubic-bezier(0.4, 0.1, 1, 1)',

  '@media (hover: hover)': {
    '&:hover': {
      color: '#160B2C',
      textDecoration: 'none',
    },
  },
  '&:first-of-type': {
    paddingLeft: 0,
  },
  '&:last-child': {
    paddingRight: 0,
  },
});

interface Props {
  simpleFooter?: boolean;
}

const Footer: FC<Props> = ({ simpleFooter = false }) => {
  const intl = useIntl();
  const { footers, image } = useStaticQuery<{
    footers: ContentfulFooterConnection;
    image: File;
  }>(graphql`
    {
      footers: allContentfulFooter {
        nodes {
          node_locale
          linkGroups {
            id
            text
            links {
              id
              text
              url
              isDisplayed
            }
          }
          copyrights
          legalLinks {
            id
            text
            url
            isDisplayed
          }
        }
      }
      image: file(relativePath: { regex: "/ce-mark/i" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const footer = footers.nodes.find((node) => node.node_locale === intl.locale);

  const LegalLinks: FC = () => {
    return (
      <>
        <div
          css={{
            marginBottom: '1.5rem',
            [presets.lg]: {
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
              visibility: 'visible',
            },
          }}
        >
          <span
            css={{
              fontWeight: 500,
              fontFamily: ['Source Code Pro']
                .concat(MONO_SYSTEM_FONTS)
                .join(', '),
              fontSize: '0.75rem',
              color: hexToRgba('#160B2C', 0.6),
              letterSpacing: 0.5,
            }}
          >
            {footer.copyrights}
          </span>
        </div>
        <div
          css={{
            display: 'none',
            [presets.lg]: {
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          {footer.legalLinks.map((link) => (
            <FooterLegalLink key={link.id} to={link.url}>
              {link.text}
            </FooterLegalLink>
          ))}
        </div>
      </>
    );
  };

  if (simpleFooter) {
    return (
      <Container
        css={{
          paddingBottom: 20,
        }}
      >
        <LegalLinks />
      </Container>
    );
  }

  return (
    <footer
      css={{
        position: 'relative',
        color: hexToRgba('#160B2C', 0.9),
      }}
    >
      <div
        css={css`
          height: 138px;
          background-color: rgb(255, 253, 252);
          background-image: url(${footerLine});
          background-repeat: no-repeat;
          background-position: top center;
          background-size: cover;

          ${presets.lg} {
            height: 220px;
          }

          @media (min-width: 2050px) {
            height: 250px;
          }

          @media (min-width: 2550px) {
            height: 10vw;
          }
        `}
      />
      <AbstractShape
        width={87}
        height={87}
        css={{
          display: 'none',
          visibility: 'hidden',
          [presets.lg]: {
            display: 'block',
            visibility: 'visible',
            position: 'absolute',
            right: '9.8%',
            top: 58,
          },
        }}
      />
      <Container>
        <Row>
          <Col
            xs={12}
            lg={2}
            css={{
              marginBottom: 64,
            }}
          >
            <Link aria-label="Cara Care homepage" to="/">
              <Logo width={130} height={30} />
            </Link>
          </Col>
          {/* First row of group links */}
          {footer.linkGroups.map((group) => (
            <Col
              key={group.id}
              xs={6}
              lg={2}
              css={{
                marginBottom: 64,
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FooterHeading>{group.text}</FooterHeading>
                {group.links
                  .filter((link) => link.isDisplayed)
                  .map((link) => (
                    <FooterLink
                      key={link.id}
                      to={link.url}
                      css={{
                        fontFamily: ['Source Code Pro']
                          .concat(MONO_SYSTEM_FONTS)
                          .join(', '),
                        fontWeight: 500,
                        fontSize: '0.75rem',
                        letterSpacing: 1.5,
                        color: 'inherit',
                        textDecoration: 'none',
                        marginBottom: '1rem',
                      }}
                    >
                      {link.text}
                    </FooterLink>
                  ))}
              </div>
            </Col>
          ))}
          {/* second row on mobile, on desktop LEGAL links are in a different place, that's why we don't display them here */}
          <Col
            xs={6}
            lg={2}
            css={{
              marginBottom: 64,
              [presets.lg]: {
                display: 'none',
                visibility: 'hidden',
              },
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FooterHeading>
                {intl.formatMessage({
                  id: 'footer.legal',
                  defaultMessage: 'Legal',
                })}
              </FooterHeading>
              {footer.legalLinks
                .filter((link) => link.isDisplayed)
                .map((link) => (
                  <FooterLink
                    key={link.id}
                    to={link.url}
                    css={{
                      fontFamily: ['Source Code Pro']
                        .concat(MONO_SYSTEM_FONTS)
                        .join(', '),
                      fontWeight: 500,
                      fontSize: '0.75rem',
                      letterSpacing: 1.5,
                      color: 'inherit',
                      textDecoration: 'none',
                      marginBottom: '1rem',
                    }}
                  >
                    {link.text}
                  </FooterLink>
                ))}
            </div>
          </Col>
          <Col
            xs={12}
            lg={4}
            lgOffset={2}
            css={{
              marginBottom: '1.5rem',
              [presets.lg]: { textAlign: 'right' },
            }}
          >
            <Image fixed={image.childImageSharp.fixed} />
            <span
              css={{
                display: 'block',
                fontWeight: 500,
                fontFamily: ['Source Code Pro']
                  .concat(MONO_SYSTEM_FONTS)
                  .join(', '),
                fontSize: '0.75rem',
                color: hexToRgba('#160B2C', 0.6),
                letterSpacing: 0.5,
              }}
            >
              {intl.formatMessage({
                id: 'footer.ceCertifiedMedicalDevice',
                defaultMessage: 'CE-certified Medical Device',
              })}
            </span>
            <span
              css={{
                display: 'block',
                fontWeight: 500,
                fontFamily: ['Source Code Pro']
                  .concat(MONO_SYSTEM_FONTS)
                  .join(', '),
                fontSize: '0.75rem',
                color: hexToRgba('#160B2C', 0.6),
                letterSpacing: 0.5,
              }}
            >
              {intl.formatMessage({
                id: 'footer.gdprCompliant',
                defaultMessage: 'GDPR Compliant',
              })}
            </span>
          </Col>
        </Row>

        <LegalLinks />
      </Container>
    </footer>
  );
};

export default Footer;
